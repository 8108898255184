<template>
  <page-layout>
    <div
      class="w-100 h-50 d-flex justify-content-center pt-5"
    >
      <b-card
        body-class="d-flex align-items-center"
        align="center"
      >
        <div
          class="px-4"
        >
          <div
            class="pb-5 title"
          >
            {{ messages.getLabelWelcomeToLogisticsSystem() }}
          </div>
          <div>
            <label>
              {{ messages.getFieldLanguage() }}:<br />
              <b-form-select
                v-model="locale"
                :disabled="isLocaleLocked"
                :options="localeOptions"
              />
            </label>
          </div>
          <div
            v-if="!profile.approvalRequestId && !profile.uid"
          >
            <div
              class="pb-4"
            >
              {{ messages.getLabelWelcomeWords() }}
            </div>
            <b-form-input
              placeholder="Manager e-mail"
              v-model="managerEmail"
              disabled
              autofocus
              type="email"
              :state="emailState"
              @input="emailState = validate($event)"
              aria-describedby="input-feedback"
            />
            <b-form-invalid-feedback
              class="text-left"
              id="input-feedback"
            >
              {{ messages.getFieldRequired() }}
            </b-form-invalid-feedback>
            <b-form-select
              v-model="selectedRole"
              :options="roles"
              class="my-2 text-muted"
              aria-describedby="select-role"
              :state="selectRoleState"
              @input="selectRoleState = validate($event)"
            >
              <template
                v-slot:first
              >
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  {{ messages.getRoleSelectionDescription() }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback
              class="text-left"
              id="select-role"
            >
              {{ messages.getFieldRequired() }}
            </b-form-invalid-feedback>
            <div
              v-show="showPlantsInput"
              class="mb-2"
            >
              <b-form-tags
                v-model="plants"
                :placeholder="`${messages.getLabelAddPlant()}...`"
                class="gray-placeholder-text"
                tag-variant="primary"
                aria-describedby="add-plant"
                add-button-variant="primary"
                :state="addPlantState"
                @input="addPlantState = validate($event)"
              />
              <b-form-invalid-feedback
                class="text-left"
                id="add-plant"
              >
                {{ messages.getFieldRequired() }}
              </b-form-invalid-feedback>
            </div>
            <div
              v-show="selectedRole === SUPERVISOR"
            >
              <b-form-tags
                v-model="plannerGroups"
                :placeholder="`${messages.getLabelAddPlannerGroup()}...`"
                class="gray-placeholder-text"
                tag-variant="primary"
                aria-describedby="add-planner-group"
                add-button-variant="primary"
                :state="addPlannerGroupState"
                @input="addPlannerGroupState = validate($event)"
              />
              <b-form-invalid-feedback
                class="text-left"
                id="add-planner-group"
              >
                {{ messages.getFieldRequired() }}
              </b-form-invalid-feedback>
            </div>
            <b-button
              @click="sendApprovalRequest"
              :disabled="disabled"
              variant="primary"
              squared
              block
              class="text-uppercase my-2"
            >
              <span
                v-show="!loading"
              >
                {{ messages.getActionSendApprovalRequest() }}
              </span>
              <b-spinner
                small
                v-show="loading"
              />
            </b-button>
          </div>
          <div
            v-if="profile.approvalRequestId && !profile.uid"
          >
            <b-spinner
              v-show="!requestStatus"
              variant="primary"
            />
            <b-card-sub-title
              v-if="requestStatus"
            >
              {{ messages.getLabelApprovalResult({
                email: $store.state.approvalRequest.managerEmail,
                requestStatus
              }) }}
            </b-card-sub-title>
          </div>
          <div
            v-if="profile.uid"
          >
            <b-card-sub-title
            >
              {{ messages.getLabelCurrentUserApproved() }}
            </b-card-sub-title>
          </div>
          <b-button
            block
            squared
            class="text-uppercase mt-3"
            @click="signOut"
          >
            {{ messages.getActionSignOut() }}
          </b-button>
        </div>
      </b-card>
    </div>
  </page-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import locales from '@/constants/locales'
import PageLayout from '../components/PageLayout'
import axios from 'axios'
import { signOutMixin } from '@/components/sign-out-mixin'
import {
  KONE_ROLES,
  MECHANIC,
  CUSTOM_SERVICE_ADMIN,
  SUPERVISOR
} from '@/constants/permissions'
import { APPROVED, REJECTED, PENDING } from '@/constants/approval_status'

export default {
  name: 'WelcomePage',
  components: { PageLayout },
  mixins: [signOutMixin],
  data () {
    return {
      loading: false,
      emailState: null,
      disabled: true,
      selectedRole: null,
      plants: [],
      selectRoleState: null,
      addPlantState: null,
      plannerGroups: [],
      addPlannerGroupState: null,
      // SUPERVISOR needs to be here so it can be accessed in template.
      SUPERVISOR: SUPERVISOR,
      messages
    }
  },
  computed: {
    localeOptions () {
      const localeOptions = []
      for (const [propKey, propValue] of Object.entries(locales)) {
        localeOptions.push({ text: propValue, value: propKey })
      }
      return localeOptions
    },
    locale: {
      get () {
        return this.$store.state.profile.locale
      },
      set (locale) {
        this.$store.dispatch('setLocale', locale)
      }
    },
    isLocaleLocked () {
      return this.$store.state.profile.isLocaleLocked ?? false
    },
    managerEmail () {
      return this.$store.getters.getManagerEmail
    },
    roles () {
      return KONE_ROLES.map(this.formatRoleLabel)
    },
    requestStatus () {
      const { approvalStatus } = this.$store.state.approvalRequest
      if ([APPROVED, PENDING, REJECTED].includes(approvalStatus)) {
        return approvalStatus
      }
      return null
    },
    profile () {
      return this.$store.state.profile
    },
    showPlantsInput () {
      return [CUSTOM_SERVICE_ADMIN, SUPERVISOR].includes(this.selectedRole)
    },
    isPlantsInputEmptyAndRequired () {
      return !this.plants.length && this.showPlantsInput
    },
    isPlannerGroupInputEmptyAndRequired () {
      return !this.plannerGroups.length && this.selectedRole === SUPERVISOR
    }
  },
  methods: {
    formatRoleLabel (role) {
      if (role === MECHANIC) {
        return { value: MECHANIC, text: messages.getRoleMechanic() }
      } else if (role === CUSTOM_SERVICE_ADMIN) {
        return { value: CUSTOM_SERVICE_ADMIN, text: messages.getRoleCustomerServiceAdmin() }
      } else if (role === SUPERVISOR) {
        return { value: SUPERVISOR, text: messages.getRoleSupervisor() }
      }
      console.warn(`No user-readable label defined for role ${role} (using raw value)`)
      return { value: '', text: '' }
    },
    sendApprovalRequest () {
      // return from method if any of required fields is empty
      if (
        !this.managerEmail || !this.selectedRole || this.isPlantsInputEmptyAndRequired ||
        this.isPlannerGroupInputEmptyAndRequired
      ) {
        // disable send button
        this.disabled = true
        // show warnings for required, empty fields
        if (!this.managerEmail) {
          this.emailState = false
        }
        if (!this.selectedRole) {
          this.selectRoleState = false
        }
        if (this.isPlantsInputEmptyAndRequired) {
          this.addPlantState = false
        }
        if (this.isPlannerGroupInputEmptyAndRequired) {
          this.addPlannerGroupState = false
        }
        return
      }
      const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/approval_requests`
      const managerEmail = this.managerEmail.toLowerCase()
      this.loading = true
      const data = { manager_email: managerEmail, role: this.selectedRole }
      if (this.showPlantsInput) {
        data.plants = this.plants
      }
      if (this.selectedRole === SUPERVISOR) {
        data.planner_groups = this.plannerGroups
      }
      axios.post(url, data)
        .then((response) => {
          this.$store.dispatch('getUserApprovalRequest', response.data.approval_request_id)
          this.$store.commit('setApprovalRequestID', response.data.approval_request_id)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('setErrorMessage', error.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    validate (value) {
      // enable send button if all required fields are filled
      if (
        this.managerEmail && this.selectedRole && !this.isPlantsInputEmptyAndRequired &&
        !this.isPlannerGroupInputEmptyAndRequired
      ) {
        this.disabled = false
      } else {
        this.disabled = true
      }

      if (Array.isArray(value)) {
        return !!value.length
      } else {
        return !!value
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 44px;
}
.gray-placeholder-text ::v-deep ::placeholder {
  color: $gray-600;
  opacity: 1;
}
</style>
